import React, { useState } from 'react';
import '../styles/Card.css';
import { FaMapMarkerAlt, FaClock, FaStar, FaHeart } from 'react-icons/fa';

const Card = ({ title, location, duration, rating, isFavorite, imageUrl, price, description }) => {
  const [isCardFavorite, setIsCardFavorite] = useState(isFavorite);

  const toggleFavorite = () => {
    setIsCardFavorite(!isCardFavorite);
  };

  // Função para ajustar o texto da localização
  const formatLocation = (location) => {
    if (location.length > 20) {
      const firstPart = location.substring(0, 20);
      const secondPart = location.substring(20);
      return (
        <>
          <span>{firstPart}</span>
          <br />
          <span>{secondPart}</span>
        </>
      );
    }
    return location;
  };

  return (
    <div className="card">
      <img src={imageUrl} alt={title} className="card-image" />
      <div className="card-content">
        <div className="card-header">
          <h3 className="card-title">{title}</h3>
          {/* icone de favorito alternando entre preenchido e não preenchido */}
          <FaHeart
            className={`favorite-icon ${isCardFavorite ? 'favorite' : 'not-favorite'}`}
            onClick={toggleFavorite} // alterna o estado de favorito ao clicar
          />
        </div>
        <p className="card-description">{description}</p> {/* exibindo a descrição */}
        <div className="card-details">
          <div className="card-info-left">
            <div className="card-location">
              <FaMapMarkerAlt className="icon-location" /> {formatLocation(location)}
            </div>
            <div className="card-duration">
              <FaClock className="icon-duration" /> {duration}
            </div>
          </div>
          <div className="card-info-right">
            <div className="card-rating">
              <FaStar className="icon-rating" /> {rating}
            </div>
            <div className="card-price">
              R$ {price}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;


