import React from 'react';
import { FaSun, FaUtensils, FaHeart, FaTree } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // 
import '../styles/Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-icons">
        <Link to="/diversao" className="footer-icon">
          <FaSun size={30} color="#a3a3a3" />
          <span>Diversão</span>
        </Link>
        <Link to="/comer&beber" className="footer-icon">
          <FaUtensils size={30} color="#a3a3a3" />
          <span>Comer&Beber</span>
        </Link>
        <Link to="/relaxar" className="footer-icon">
          <FaTree size={30} color="#a3a3a3" />
          <span>Relaxar</span>
        </Link>
        <Link to="/favorites" className="footer-icon">
          <FaHeart size={30} color="#a3a3a3" />
          <span>Favoritos</span>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
