import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Corrigido: Remova a pasta 'pages' da importação, pois Home.js e MainScreen.js estão diretamente dentro de src
import Home from './Home';  // Home.js está na pasta 'pages', não é necessário colocar 'pages/' no caminho
import MainScreen from './MainScreen';  // MainScreen.js está na pasta 'pages', o mesmo vale aqui

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/main" element={<MainScreen />} />
      </Routes>
    </Router>
  );
}

export default App;

