import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import logo from "../styles/fotos/MeGuia_logo.png"; // Logo do App
import "./../styles/App.css"; // Estilos do App

const Home = () => {
  const navigate = useNavigate(); // Criar o hook de navegação

  useEffect(() => {
    // definir o tempo de exibição da tela de carregamento (por exemplo, 3 segundos)
    const timer = setTimeout(() => {
      navigate('/main'); // após o tempo, redirecionar para a MainScreen
    }, 3000);

    // limpar o timer caso o componente seja desmontado
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="home-container">
      <img src={logo} alt="Logo do App" className="app-logo" />
    </div>
  );
};

export default Home;
