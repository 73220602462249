import React from 'react';
import { FaSearch, FaMicrophone } from 'react-icons/fa';
import '../styles/SearchBar.css'; // importando o CSS da barra de pesquisa

const SearchBar = () => {
  return (
    <div className="search-bar">
      <div className="search-icon">
        <FaSearch />
      </div>
      <input type="text" className="search-input" placeholder="Buscar" />
      <div className="microphone-icon">
        <FaMicrophone />
      </div>
    </div>
  );
};

export default SearchBar;
