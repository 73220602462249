import React from 'react';
import '../styles/MainScreen.css';
import SearchBar from '../components/SearchBar';
import Card from '../components/Card';
import Footer from '../components/Footer'; // Importando o Footer

const MainScreen = () => {
  const cardData = [
    {
      title: 'Passeio de Buggy',
      location: 'Canoa Quebrada',
      duration: 'Dura 2 horas',
      rating: 3.8,
      description: "Uma maneira emocionante e única de explorar a região.",
      imageUrl: 'https://www.praiasdefortaleza.net/wp-content/uploads/2016/08/Passeio-de-buggy-em-Cumbuco-.jpg',
      price: '320,00', 
    },
    {
      title: 'Voo de Parapente',
      location: 'Canoa Quebrada',
      duration: 'Dura 20 minutos',
      rating: 4.2,
      description: "Proporciona uma vista aérea espetacular das praias, dunas e da paisagem.",
      imageUrl: 'https://media-cdn.tripadvisor.com/media/photo-s/07/36/cf/30/parapente-canoa-quebrada.jpg',
      price: '250,00',
    },
    {
      title: 'Gaiola Off Road',
      location: 'Canoa Quebrada / Arriégua Adventure',
      duration: 'Dura 1 hora e 30 minutos',
      rating: 4.1,
      description: "É uma maneira emocionante e segura de explorar terrenos desafiadores.",
      imageUrl: 'https://img.olx.com.br/images/87/873433820642638.webp',
      price: '350,00',
    },
    {
      title: 'Kite Surf',
      location: 'Canoa Quebrada',
      duration: 'Dura 1 hora',
      rating: 4.6,
      description: "A aula de Kite Surf acontece durante o dia, pela manhã ou fim da tarde...",
      imageUrl: 'https://blog.ecoadventure.tur.br/wp-content/uploads/2023/10/person-surfing-flying-parachute-same-time-kitesurfing-bonaire-caribbean_181624-11389.jpg',
      price: '180,00',
    },
  ];

  return (
    <div className="main-screen-container">
      <div className="search-bar-container">
        <h2 className="title">Diversão</h2>
        <SearchBar />
      </div>
      <div className="card-list">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            location={card.location}
            duration={card.duration}
            rating={card.rating}
            isFavorite={card.isFavorite}
            imageUrl={card.imageUrl}
            price={card.price} 
            description={card.description}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default MainScreen;

